
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import IQCCategoryItemTable from "@/components/IQC/IQCCategoryItemTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    IQCCategoryItemTable
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const gridOptions: GridOptions = {
      stripe: false,
      title: '來料檢驗類別管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      printConfig: { sheetName: '來料檢驗類別管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '來料檢驗類別管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'Name',
          title: '名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'Ordinal',
          title: '排序',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        { field: 'Published', title: '已發佈', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'Ordinal', order: 0 }, { column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('iqcCategory/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('iqcCategory/query') : undefined,
        save: model ? (params) => model.dispatch('iqcCategory/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Published',
          title: '已發佈',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Ordinal',
          title: '排序',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          title: '項目資料',
          span: 24,
          slots: { default: 'column-categoryItem' }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Published: [{ required: true }],
        Ordinal: [{ required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      row.Published = false;
      row.Ordinal = 0;
      callback();
    }

    const itemsTable = ref<any>();
    const formSubmit = (row: any, submit: any) => {
      var items = itemsTable?.value.getData();
      console.log(items)
      if(items)
        row.Items = items;
      
      submit();
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      initData,
      itemsTable,
      formSubmit,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})

<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form v-if="!readType" class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="名稱" field="Name" :item-render="{}" span="8">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Name" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="排序" field="Ordinal" :item-render="{}" span="8">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Ordinal" placeholder="請輸入數字" ></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" :content="table.isEditing ? '修改' : '確認'">
            </vxe-button>
            <vxe-button v-if="table.isEditing" type="button" @click="table.reset" status="danger" content="取消">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Ordinal" title="排序" sortable>
      </vxe-column>
      <vxe-column field="Name" title="名稱" sortable>
      </vxe-column>
      <vxe-column v-if="!readType" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import { formatAmount, formatTotalAmount, formatCBMAmount } from '@/libs/utils'
import { commafy } from "xe-utils";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    customerId: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Ordinal', order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        // console.log(row)
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();

        emit('updateTotal');
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {};
        table.formData.Ordinal = 0;
        table.formData.Published = true;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {        
        if(table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
        await grid.value.sort("Ordinal", 'asc');
        emit('updateTotal');
      },
      rules: {
        Name: [{ required: true }],
      }
    })

    watch(() => props.aid, async(value) => {
      if(value) {
        // console.log(props.data)
        var t = Date.now();
        if(props.data && props.data.length > 0) {         
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();        
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const TF = [{ label: "是", value: true }, { label: "否", value: false }];

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      model,
      TF,
      commafy,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
